<template>
    <div class="filter_wrap">
        <div class="fixed">
            <div class="categoryFilter">
                <div>
                    <button class="cf_title active">
                        멘토가입대기
                    </button>
                    <button class="cf_title ">
                        멘티가입대기
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "MemberFilterTypeLayout",
        props: ['filterData', 'setData'],
        data() {
            return {

            }
        },
        computed: {

        },
        mounted() {

        },
        methods: {

        },
        watch: {
        },
    }
</script>

<style scoped>

</style>